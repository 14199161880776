<template>
  <div ref="chart" :style="{ width, height }"></div>
</template>
<script>
import * as echarts from 'echarts';

export default {
  name: 'Chart',
  props: {
    option: {
      type: Object,
      default: () => {}
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '100%'
    },
    svg: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      chart: null
    };
  },
  watch: {
    option: {
      handler(newOption) {
        this.chart.setOption(newOption);
      },
      deep: true
    }
  },
  mounted() {
    this.chart = echarts.init(this.$el, null, { renderer: this.svg ? 'svg' : 'canvas' });
    this.chart.setOption(this.option);
  },
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  }
};
</script>
